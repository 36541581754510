/* global jQuery */

import passiveEvent from "utils/passiveEvent";

const $ = jQuery;

/*
 * Make header sticky when the user scrolls past a certain offset.
 */
(() => {
  const $header = $("#header");

  // Vertical scroll offset past which the header should be made sticky.
  const stickyOffset = 110;

  const onScroll = () => {
    $header.toggleClass("Header--sticky", window.pageYOffset > stickyOffset);
  };

  window.addEventListener("scroll", onScroll, passiveEvent());
  onScroll();
})();

/*
 * The header can be in one of the following states:
 *
 *  - S_INITIAL
 *  - S_PRIMARY_MENU (only on mobile where the menu items are shown in a full-viewport overlay)
 *  - S_TABLE_OF_CONTENTS (only on article pages)
 *  - S_SEARCH
 *
 * The state is reflected in the DOM, as a data-state attribute on the <header id="header"> element.
 * This is so that the look of the header can be styled according to it.
 */
(() => {
  const S_INITIAL = "initial";
  const S_PRIMARY_MENU = "primary-menu";
  const S_TABLE_OF_CONTENTS = "table-of-contents";
  const S_SEARCH = "search";

  const $header = $("#header");
  const $primaryMenuElements = $("#primary-menu-toggle, #primary-menu");
  const $tableOfContentsElements = $("#table-of-contents-toggle, #table-of-contents-toggle");

  const render = () => {
    const state = $header.attr("data-state") || S_INITIAL;

    $primaryMenuElements.attr("aria-expanded", JSON.stringify(state === S_PRIMARY_MENU || state === S_SEARCH));
    $tableOfContentsElements.attr("aria-expanded", JSON.stringify(state === S_TABLE_OF_CONTENTS));
  };

  $("#primary-menu-toggle").click(() => {
    const currentState = $header.attr("data-state") || S_INITIAL;
    const nextState = currentState === S_PRIMARY_MENU || currentState === S_SEARCH ? S_INITIAL : S_PRIMARY_MENU;
    $header.attr("data-state", nextState);

    console.log("pmt", currentState, nextState);

    render();
  });

  $("#table-of-contents-toggle").click(() => {
    const currentState = $header.attr("data-state") || S_INITIAL;
    const nextState = currentState === S_TABLE_OF_CONTENTS ? S_INITIAL : S_TABLE_OF_CONTENTS;
    $header.attr("data-state", nextState);

    render();
  });

  $("#table-of-contents a").click(() => {
    $header.attr("data-state", S_INITIAL);
    render();
  });

  $(".Header-searchButton").click(() => {
    const currentState = $header.attr("data-state") || S_INITIAL;
    const nextState = currentState === S_SEARCH ? S_INITIAL : S_SEARCH;
    $header.attr("data-state", nextState);

    render();

    if (nextState === S_SEARCH) {
      $(`.Header .search-form input[type="search"]`).focus();
    }
  });

  document.addEventListener("mousedown", ev => {
    const state = $header.attr("data-state") || S_INITIAL;
    if (state === S_SEARCH) {
      const $t = $(ev.target);
      if ($t.closest(".Header").length === 0) {
        ev.preventDefault();
        ev.stopPropagation();

        $header.attr("data-state", S_INITIAL);
        render();
      }
    }
  });
})();
