const fallbackToString = (value: Record<string, string> | string | null | void, lang: string) => {
  if (typeof value === "string" || value === null || value === undefined) {
    return value;
  }
  return value[lang];
};

export default (image, lang) => {
  return {
    ...image,
    title: fallbackToString(image.title, lang),
    alt: fallbackToString(image.alt, lang),
    caption: fallbackToString(image.caption, lang),
    sourceText: fallbackToString(image.sourceText, lang),
    sourceUrl: fallbackToString(image.sourceUrl, lang),
    focalPoints: image.focalPoints.map(focalPoint => ({
      ...focalPoint,
      title: fallbackToString(focalPoint.title, lang),
      caption: fallbackToString(focalPoint.caption, lang)
    })),
    // imageMapPoints may be empty for existing images if their metadata hasn't been refreshed.
    imageMapPoints: image.imageMapPoints
      ? image.imageMapPoints.map(imageMapPoint => ({
          ...imageMapPoint,
          title: fallbackToString(imageMapPoint.title, lang),
          caption: fallbackToString(imageMapPoint.caption, lang)
        }))
      : [],
    morph: image.morph ? { ...image.morph, label: fallbackToString(image.morph.label, lang) } : {}
  };
};
