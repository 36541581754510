/* eslint-disable no-nested-ternary */

import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player/lib/players/Vimeo";

import "./Vimeo.scss";
import Caption from "../../Figure/Caption";

const figCaptionId = id => `figcaption-vimeo-${id}`;

const Vimeo = ({ videoId, layout, title, caption, sourceUrl, sourceText }) => (
  <figure className="Vimeo-figure" aria-labelledby={figCaptionId(videoId)}>
    <div
      className={`PageContent ${
        layout === "inline" ? "PageContent--narrow" : layout === "wide" ? "PageContent--wide" : ""
      }`}
    >
      <div className="Vimeo">
        <ReactPlayer
          className="Vimeo-player"
          width="100%"
          height="100%"
          url={`https://vimeo.com/${videoId}`}
          controls={true}
          config={{
            vimeo: {
              iframeParams: {
                color: "A8322D"
              }
            }
          }}
        />
      </div>
    </div>
    <div className={`PageContent PageContent--narrow`}>
      <figcaption id={figCaptionId(videoId)} className="Vimeo-caption">
        {title && <Caption title={title} caption={caption} sourceUrl={sourceUrl} sourceText={sourceText} />}
      </figcaption>
    </div>
  </figure>
);

Vimeo.propTypes = {
  videoId: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(["inline", "wide", "full-width"]).isRequired
};

Vimeo.defaultProps = {
  layout: "inline"
};

export default Vimeo;
