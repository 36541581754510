import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';
import {MappedImage} from './MappedImage';
import Bubble from 'components/Share/Bubble';
import FullScreenCaption from 'components/Figure/FullscreenCaption';
import ShareMenu from 'components/Share/Menu';

import './MappableImage.scss';

const STAGE_INITIAL = 'INITIAL';
const STAGE_FADING_IN = 'FADING_IN';
const STAGE_FADED_IN = 'FADED_IN';
const STAGE_FADING_OUT = 'FADING_OUT';

const FocalPoint = ({x, y, title, caption, image, onClick, index}) => (
  <div className='MappableImage-FocalPointContainer'
    style={{
      width: `30px`,
      height: 0,
      paddingBottom: `30px`,
      top: `${y * 100}%`,
      left: `${x * 100}%`,
    }}
  >
    <div
      className='MappableImage-FocalPoint'
      onClick={() => onClick({title, caption, image})}
    >
      {index}
    </div>
  </div>
);

FocalPoint.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

const currentURLWithQueryParam = (k: string, v: string): string => {
  const {protocol, host, pathname} = window.location;
  return `${protocol}//${host}${pathname}?${k}=${encodeURIComponent(v)}`;
};

const shareMenuProps = (title: string, text: string, imageId: number) => ({
  ...ShareMenu.defaultProps,
  tweet: title,
  subject: title,
  description: text,
  url: currentURLWithQueryParam('share', `image-${imageId}`),
});

export class MappableImage extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    shareTitle: PropTypes.string.isRequired,
    shareText: PropTypes.string.isRequired,
    lowresProps: PropTypes.object.isRequired,
    highresProps: PropTypes.object.isRequired,
    imageClassName: PropTypes.string,
    caption: PropTypes.node,
    imageMapPoints: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      title: PropTypes.string,
      caption: PropTypes.string,
      image: PropTypes.object.isRequired,
    })).isRequired
  }

  state = {
    stage: STAGE_INITIAL
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate() {
    const {stage} = this.state;
    if (stage !== STAGE_INITIAL) {
      this.renderMappedImage();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({keyCode}) => {
    const {stage} = this.state;
    if ((stage === STAGE_FADED_IN) && keyCode === 27) { // escape
      this.exitMap();
    }
  }

  enterMap = (mapTo) => {
    this.mapTarget = document.body.appendChild(document.createElement('div'));
    this.setState({
      stage: STAGE_FADING_IN,
      mapCaption: mapTo.caption ? {caption: mapTo.caption, title: mapTo.title} : null,
      image: mapTo.image,
    });
  }

  exitMap = () => {
    this.setState({
      stage: STAGE_FADING_OUT,
    });
  }

  exitedMap = () => {
    document.body.removeChild(this.mapTarget);
    this.setState({
      stage: STAGE_INITIAL,
      mapCaption: null
    });
  }

  enteredMap = () => {
    this.setState({
      stage: STAGE_FADED_IN
    });
  }

  renderMappedImage() {
    const {id, caption, shareTitle, shareText} = this.props;
    const {stage, mapCaption, image} = this.state;

    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
          }}
        >
          <MappedImage
            fadeIn={stage === STAGE_FADING_IN || stage === STAGE_FADED_IN}
            onFadeIn={this.enteredMap}
            onFadeOut={this.exitedMap}
            src={image.src}
          />
          <div
            style={{
              position: 'fixed',
              left: 0,
              right: 0,
              bottom: 0,
              opacity: stage === STAGE_FADED_IN ? 1 : 0,
              transition: 'opacity 1s'
            }}
          >
          { mapCaption
            ? <FullScreenCaption title={mapCaption.title} caption={mapCaption.caption} />
            : caption
          }
            </div>
          {shareTitle && <div style={{
            background: 'transparent',
            padding: 0,
            position: 'fixed',
            top: 20,
            right: 62,
            opacity: stage === STAGE_FADED_IN ? 1 : 0,
            transition: 'opacity 0.3s'
          }}>
            <ShareMenu {...shareMenuProps(shareTitle, shareText, id)} />
          </div>}
          <button
            style={{
              background: 'transparent',
              padding: 0,
              position: 'fixed',
              top: 20,
              right: 20,
              opacity: stage === STAGE_FADED_IN ? 1 : 0,
              transition: 'opacity 0.3s'
            }}
            onClick={this.exitMap}>
              <Bubble icon={'close'} />
            </button>
        </div>
      ),
      this.mapTarget
    );
  }

  render() {
    const {lowresProps, imageMapPoints} = this.props;

    return (
      <div
        style={{
          position: 'relative'
        }}
      >
        <img
          alt={lowresProps.alt}
          {...lowresProps}
          ref={(el) => {this.el = el;}}
        />
        {
          imageMapPoints
            .map((point, i) => <FocalPoint key={i} {...point} index={i + 1} onClick={this.enterMap} />)
        }


        {
          // mapping &&
          // focalPoints.map((point, i) => (
          //   <button
          //   key={i}
          //   style={{
          //     position: 'fixed',
          //     top: 20,
          //     right: 50 + i * 30
          //   }}
          //   onClick={() => this.enterMappoint)}>{i + 1}</button>
          // ))
        }
      </div>
    );
  }
}
