import React, {PureComponent, Component} from 'react';
import PropTypes from 'prop-types';
import {zoom, zoomTransform, zoomIdentity} from 'd3-zoom';
import {select, event} from 'd3-selection';
import 'd3-transition';
import shallowEqual from 'shallowequal';

import './ZoomedImage.scss';

/* Keep this in sync with the duration that is defined in the SCSS file! */
const ZOOM_DURATION = 1000;

class PureImage extends PureComponent {
  render() {
    return <img alt={this.props.alt} {...this.props} />;
  }
}

export class ZoomedImage extends Component {
  static propTypes = {
    zoomTo: PropTypes.object,
    highresProps: PropTypes.object.isRequired,
    lowresProps: PropTypes.object.isRequired,
    offset: PropTypes.object.isRequired,
    onZoomIn: PropTypes.func.isRequired,
    onZoomOut: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.state = {
      transform: {x: 0, y: 0, k: 1}
    };

    this.el = null;
    this.elRef = (el) => {this.el = select(el);};
  }

  componentDidMount() {
    const {offset} = this.props;
    this.zoom = zoom()
      .on('zoom', () => {
        const transform = zoomTransform(this.el.node());
        this.setState(() => ({transform}));
      });

    this.el
      .call(this.zoom.transform, zoomIdentity.translate(offset.left, offset.top))
      .on('wheel', () => { event.preventDefault(); });
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({zoomTo, offset}) {
    if (zoomTo === null) {
      this.zoomOut(offset);
    } else {
      if (!shallowEqual(this.props.zoomTo, zoomTo) || !shallowEqual(this.props.offset, offset)) {
        this.zoomTo(zoomTo, offset);
      }
    }
  }

  zoomTo = ({x, y, k, d}, {width, height}) => {
    const viewportWidth = this.el.node().clientWidth;
    const viewportHeight = this.el.node().clientHeight;

    // scale at least to full viewport dimensions
    const minScale = Math.max(viewportWidth / width, viewportHeight / height) * 1.2;
    const scale = Math.max(
      minScale,
      k ? k : Math.min(viewportWidth / width / d, viewportHeight / width / d)
    );
    // FIXME: ideally the maximum scale would depend on the dimensions of the highres image
    const maxScale = scale;

    this.zoom
      .scaleExtent([minScale, maxScale])
      .translateExtent([[-100, -100], [width + 100, height + 100]]);

    this.el
      .on('zoom', null)  // disable zoom interaction
    .transition()
      .duration(ZOOM_DURATION)
      .call(this.zoom.transform, zoomIdentity.translate(viewportWidth / 2, viewportHeight / 2).scale(scale).translate(-width * x, -height * y))
      .on('end', () => {
        this.el.call(this.zoom); // enable zoom interaction
        this.setState(() => ({zoomed: true}));
        this.props.onZoomIn();
      });
  }

  zoomOut = ({left, top}) => {
    this.el
      .on('.zoom', null) // disable zoom interaction
    .transition()
      .duration(ZOOM_DURATION)
      .call(this.zoom.transform, zoomIdentity.translate(left, top))
      .on('end', this.props.onZoomOut);
  }

  onHighresLoaded = () => {
    this.setState({highresLoaded: true});
  }

  onLowresLoaded = () => {
    const {zoomTo, offset} = this.props;
    this.zoomTo(zoomTo, offset);
  }

  render() {
    const {lowresProps, highresProps, offset: {width, height}, zoomTo} = this.props;
    const {zoomed, highresLoaded, transform: {x, y, k}} = this.state;
    const isZooming = x !== 0 || y !== 0 || k !== 1;
    const transformValue = `translate(${x}px,${y}px) scale(${k})`;

    return (
      <div ref={this.elRef} className='ZoomedImage'>
        <div className={`ZoomedImage-backdrop${isZooming && zoomTo ? ' ZoomedImage-backdrop--visible' : ''}`} />
        <div
          style={{
            width,
            height,
            position: 'absolute',
            top: 0,
            left: 0,
            transformOrigin: 'top left',
            transform: transformValue,
            WebkitTransform: transformValue
          }}>
            <PureImage
              {...lowresProps}
              srcSet={lowresProps['data-srcset']}
              className={`ZoomedImage-img${isZooming ? ' ZoomedImage-img--visible' : ''}`}
              role='presentation'
              onLoad={this.onLowresLoaded}
            />
            {
              zoomed &&
              <PureImage
                {...highresProps}
                className={`ZoomedImage-img${highresLoaded ? ' ZoomedImage-img--visible' : ''}`}
                onLoad={this.onHighresLoaded}
                role='presentation'
              />
            }
        </div>
      </div>
    );
  }
}
