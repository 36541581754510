/* eslint-disable no-nested-ternary */

import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player/lib/players/FilePlayer";
import formatDuration from "format-duration";

import "./Audio.scss";

const FMT_OPTIONS = { leading: false };
const formatS = seconds => (seconds === undefined ? "-:--" : formatDuration(seconds * 1000, FMT_OPTIONS));

const DownloadIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
    <path
      d="M10.966 6.685c-.06-.164-.194-.268-.34-.268h-1.5V.458C9.125.205 8.956 0 8.75 0h-1.5c-.207 0-.375.205-.375.458v5.959h-1.5c-.147 0-.28.105-.341.268a.539.539 0 0 0 .059.492l2.625 3.666A.35.35 0 0 0 8 11c.108 0 .21-.058.282-.157l2.625-3.666a.538.538 0 0 0 .06-.492zM13.867 12v2.4H2.133V12H0v3.2c0 .442.478.8 1.067.8h13.866c.59 0 1.067-.358 1.067-.8V12h-2.133z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const PlayIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 23 29" {...props}>
    <path d="M0 0v29l23-14.5z" fill="currentColor" fillRule="evenodd" />
  </svg>
);
const PauseIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 12 16" {...props}>
    <path d="M4 16H0V0h4v16zm8 0H8V0h4v16z" fill="currentColor" />
  </svg>
);

const titlePlay = {
  de: "Abspielen",
  en: "Play"
};
const titlePause = {
  de: "Anhalten",
  en: "Pause"
};
const titleDownload = {
  de: "Audio-Datei herunterladen",
  en: "Download audio file"
};
const titlePlayer = {
  de: "Audio-Player",
  en: "Audio Player"
};
const titleTitle = {
  de: title => `«${title}» anhören`,
  en: title => `Listen to “${title}”`
};

class Audio extends React.Component {
  state = {
    playing: false,
    duration: undefined,
    played: undefined,
    loaded: undefined,
    playedSeconds: undefined,
    loadedSeconds: undefined,
    ready: false,
    seekTo: undefined,
    seeking: false
  };

  playerRef = React.createRef();

  getSeekPosition = e => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    let currentEvent = e;
    if (e.changedTouches) {
      currentEvent = e.changedTouches[0];
    }
    const x = currentEvent.clientX - left;
    const ratio = Math.max(0, Math.min(1, x / width));
    return ratio;
  };

  seek = e => {
    e.stopPropagation();

    const seekTo = this.getSeekPosition(e);

    this.setState({ seeking: true, seekTo: seekTo });
  };

  seekEnd = e => {
    e.stopPropagation();
    const seekTo = this.getSeekPosition(e);

    if (this.playerRef.current) {
      this.playerRef.current.seekTo(seekTo);
    }
  };

  playerDuration = s => {
    this.setState({ duration: s });
  };
  playerProgress = p => {
    this.setState(p);
  };
  playerReady = () => {
    this.setState({ ready: true });
  };
  playerSeek = s => {
    this.setState({ seeking: false, seekTo: undefined, playedSeconds: s });
  };
  playerEnded = () => {
    this.setState({ playing: false });
  };

  togglePlay = () => {
    this.setState(state => ({ playing: !state.playing }));
  };

  render() {
    const { url, title, layout, lang } = this.props;
    const { ready, playing, duration, loaded, playedSeconds, seeking, seekTo } = this.state;
    return (
      <div
        className={`PageContent ${
          layout === "inline" ? "PageContent--narrow" : layout === "wide" ? "PageContent--wide" : ""
        }`}
      >
        <div className="Audio" aria-label={titlePlayer[lang]}>
          <div className="Audio-title">
            <img
              alt="Podcast-Icon"
              className="Audio-icon"
              src="/wp-content/themes/eth-explora/static/ic_audio.svg"
              style={{ width: 32, height: 32 }}
            />
            <div className="Audio-titleText">{titleTitle[lang](title)}</div>
            <a className="Audio-downloadLink" href={url} download title={titleDownload[lang]}>
              <DownloadIcon />
            </a>
          </div>
          {ready && (
            <div className="Audio-controls">
              <button
                title={playing ? titlePause[lang] : titlePlay[lang]}
                className="Audio-playButton"
                onClick={this.togglePlay}
              >
                {playing ? <PauseIcon /> : <PlayIcon />}
              </button>
              <div className="Audio-progressBar">
                <div className="Audio-track Audio-trackLoaded" style={{ width: `${loaded * 100}%` }} />
                <div
                  className="Audio-track Audio-trackPlayed"
                  style={{ width: `${(seeking ? seekTo : playedSeconds / duration) * 100}%` }}
                />
                <div
                  className="Audio-scrubber"
                  onMouseDown={this.seek}
                  onMouseMove={seeking ? this.seek : undefined}
                  onMouseUp={seeking ? this.seekEnd : undefined}
                  onTouchStart={this.seek}
                  onTouchMove={seeking ? this.seek : undefined}
                  onTouchEnd={seeking ? this.seekEnd : undefined}
                />
              </div>

              <div className="Audio-duration">
                {formatS(playedSeconds)} / {formatS(duration)}
              </div>
            </div>
          )}
          <ReactPlayer
            ref={this.playerRef}
            className="Audio-player"
            url={url}
            playsinline
            playing={playing}
            onDuration={this.playerDuration}
            onProgress={this.playerProgress}
            onReady={this.playerReady}
            onSeek={this.playerSeek}
            onEnded={this.playerEnded}
          />
        </div>
      </div>
    );
  }
}

Audio.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(["inline", "wide", "full-width"]).isRequired,
  lang: PropTypes.string.isRequired
};

Audio.defaultProps = {
  layout: "inline",
  lang: "de"
};

export default Audio;
