import React from "react";
import PropTypes from "prop-types";

import BlockAnchor from "components/BlockAnchor/BlockAnchor";
import ShareMenu from "components/Share/Menu";

import "./ShortStoryChapter.scss";

interface Props {
  blockId?: number;
  tag?: React.ReactNode;
  title?: React.ReactNode;
  shareMenuProps?: any;
}

export const ShortStoryChapter = ({ blockId, tag, title, shareMenuProps }: Props) => (
  <div className="PageContent PageContent--narrow StyledContent ShortStoryChapter">
    {blockId !== undefined && <BlockAnchor blockId={blockId} />}

    {tag && <div className="ShortStoryChapter-tag">{tag}</div>}

    {title && (
      <h2 className="ShortStoryChapter-title">
        {shareMenuProps && (
          <span className="ShortStoryChapter-shareMenu">
            <ShareMenu {...shareMenuProps} />
          </span>
        )}
        {title}
      </h2>
    )}
  </div>
);

ShortStoryChapter.propTypes = {
  blockId: PropTypes.number,
  title: PropTypes.string,
  shareMenuProps: PropTypes.shape(ShareMenu.propTypes),
};
