/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";

import BlockAnchor from "components/BlockAnchor/BlockAnchor";
import ShareMenu from "components/Share/Menu";

import "./Text.scss";

const Text = ({ blockId, text, title, shareMenuProps }) => (
  <div className="PageContent PageContent--narrow StyledContent Text">
    {blockId !== undefined && <BlockAnchor blockId={blockId} />}

    {title && (
      <h2 className="Text-title">
        {shareMenuProps && (
          <span className="Text-shareMenu">
            <ShareMenu {...shareMenuProps} />
          </span>
        )}
        {title}
      </h2>
    )}

    <div className="Text-content">
      {text.split("\n\n").map((paragraph, i) => (
        <p key={i} className="Text-paragraph" dangerouslySetInnerHTML={{ __html: paragraph }} />
      ))}
    </div>
  </div>
);

Text.propTypes = {
  blockId: PropTypes.number,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  shareMenuProps: PropTypes.shape(ShareMenu.propTypes)
};

export default Text;
