/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";
import "./FigureImage.scss";

import { ZoomableImage } from "components/ZoomableImage/ZoomableImage";
import { MappableImage } from "components/MappableImage/MappableImage";
import Caption from "./Caption";
import FullscreenCaption from "./FullscreenCaption";
import ShareMenu from "components/Share/Menu";

const figCaptionId = (id) => `figcaption-${id}`;
const srcSet = (sizes) =>
  Object.keys(sizes)
    .filter((size) => size !== "full")
    .map((size) => sizes[size])
    .sort((a, b) => a.width - b.width)
    .map(({ src, width }) => {
      return `${src} ${width}w`;
    })
    .join(",");

const currentURLWithQueryParam = (k: string, v: string): string => {
  const { protocol, host, pathname } = window.location;
  return `${protocol}//${host}${pathname}?${k}=${encodeURIComponent(v)}`;
};

const shareMenuProps = (title: string, text: string, imageId: number) => ({
  ...ShareMenu.defaultProps,
  tweet: title,
  subject: title,
  description: text,
  url: currentURLWithQueryParam("share", `image-${imageId}`),
});

export const FigureImageContent = ({
  id,
  alt,
  caption,
  title,
  sourceUrl,
  sourceText,
  sizes,
  imageClassName,
  zoomable,
  mappable,
  focalPoints,
  imageMapPoints,
}) => {
  if (zoomable && focalPoints.length > 0) {
    return (
      <ZoomableImage
        id={id}
        shareTitle={title}
        shareText={caption}
        lowresProps={{
          className: `${imageClassName || "Image-image"} ${sizes.placeholder ? "Image-lqip" : ""} lazyload`,
          src: sizes.m.src,
          srcSet: sizes.placeholder ? sizes.placeholder.src : sizes.s.src,
          "data-srcset": srcSet(sizes),
          "data-sizes": "auto",
          alt: alt,
        }}
        highresProps={{
          src: sizes.xxl.src,
        }}
        focalPoints={focalPoints}
        caption={<FullscreenCaption title={title} caption={caption} sourceUrl={sourceUrl} sourceText={sourceText} />}
      />
    );
  }

  if (mappable && imageMapPoints.length > 0) {
    return (
      <MappableImage
        id={id}
        shareTitle={title}
        shareText={caption}
        lowresProps={{
          className: `${imageClassName || "Image-image"} ${sizes.placeholder ? "Image-lqip" : ""} lazyload`,
          src: sizes.m.src,
          srcSet: sizes.placeholder ? sizes.placeholder.src : sizes.s.src,
          "data-srcset": srcSet(sizes),
          "data-sizes": "auto",
          alt: alt,
        }}
        highresProps={{
          src: sizes.xxl.src,
        }}
        imageMapPoints={imageMapPoints}
        caption={<FullscreenCaption title={title} caption={caption} sourceUrl={sourceUrl} sourceText={sourceText} />}
      />
    );
  }

  return (
    <img
      className={`${imageClassName || "Image-image"} ${sizes.placeholder ? "Image-lqip" : ""} lazyload`}
      src={sizes.m.src}
      srcSet={sizes.placeholder ? sizes.placeholder.src : sizes.s.src}
      data-srcset={srcSet(sizes)}
      data-sizes="auto"
      alt={alt}
    />
  );
};

const FigureImage = (props) => {
  const { id, caption, title, sourceUrl, sourceText, figureClassName, captionClassName } = props;

  return (
    <figure className={figureClassName || "Image-figure"} aria-labelledby={figCaptionId(id)}>
      <div className={"Image-imageContainer"}>
        <FigureImageContent {...props} />
        <div
          className="Image-figureShare"
          style={{
            background: "transparent",
            padding: 0,
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <ShareMenu {...shareMenuProps(title, caption, id)} />
        </div>
      </div>
      <figcaption id={figCaptionId(id)} className={captionClassName || "Image-caption"}>
        <Caption title={title} caption={caption} sourceUrl={sourceUrl} sourceText={sourceText} />
      </figcaption>
    </figure>
  );
};

// These reflect the structure of WP's media attachment

const SizePropType = PropTypes.shape({
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
});

FigureImage.propTypes = {
  id: PropTypes.number.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  // description: Proptypes.string.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  sourceText: PropTypes.string.isRequired,
  sizes: PropTypes.shape({
    xxl: SizePropType.isRequired,
    xl: SizePropType,
    l: SizePropType,
    m: SizePropType.isRequired,
    s: SizePropType,
    placeholder: SizePropType,
  }).isRequired,
  figureClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  captionClassName: PropTypes.string,
  zoomable: PropTypes.bool,
  mappable: PropTypes.bool,
  focalPoints: PropTypes.array.isRequired,
  imageMapPoints: PropTypes.array.isRequired,
};

FigureImage.defaultProps = {
  focalPoints: [],
  imageMapPoints: [],
};

FigureImageContent.propTypes = FigureImage.propTypes;

export default FigureImage;
