import React from 'react';
import PropTypes from 'prop-types';
import Bubble from './Bubble';
import plattforms, {defaultProps, propTypes} from './plattforms';

import './Section.scss';

const Section = (props) => {
  const {title} = props;
  return (
    <div className='ShareSection'>
      <h2>{title}</h2>
      <ul role='menu' className='ShareSection-options'>
        {plattforms.map(({href, icon, target}, i) => {
          return <li key={i} role='menuitem'><a href={href(props)} target={target}><Bubble icon={icon} opaque /></a></li>;
        })}
      </ul>
    </div>
  );
};

Section.defaultProps = defaultProps;
Section.propTypes = {
  ...propTypes,
  title: PropTypes.string.isRequired
};

export default Section;
