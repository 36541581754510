import React from "react";
import PropTypes from "prop-types";

import "./MorphSelector.scss";

export class MorphSelector extends React.PureComponent {
  state = {
    overflowL: false,
    overflowR: false,
    scrollAnimation: undefined
  };

  componentDidMount() {
    this.updateOverflow();
  }

  componentDidUpdate() {
    this.updateOverflow();

    if (this.state.scrollAnimation !== undefined && this.scrollAnimationId === undefined) {
      this.scrollAnimationId = requestAnimationFrame(this.updateScrollPosition);
    }
  }

  componentWillUnmount() {
    if (this.overflowUpdateTimeoutId !== undefined) {
      clearTimeout(this.overflowUpdateTimeoutId);
      this.overflowUpdateTimeoutId = undefined;
    }

    if (this.scrollAnimationId !== undefined) {
      cancelAnimationFrame(this.scrollAnimationId);
      this.scrollAnimationId = undefined;
    }
  }

  overflowUpdateTimeoutId = undefined;
  scrollAnimationId = undefined;

  rootRef = null;
  rootRefFn = ref => {
    this.rootRef = ref;
    this.updateOverflow();
  };

  updateOverflow = () => {
    if (this.overflowUpdateTimeoutId !== undefined) {
      clearTimeout(this.overflowUpdateTimeoutId);
    }

    this.overflowUpdateTimeoutId = setTimeout(() => {
      if (this.rootRef) {
        const { scrollLeft, scrollWidth, clientWidth } = this.rootRef;

        const nextState = {
          overflowL: scrollLeft > 0,
          overflowR: scrollWidth - scrollLeft - clientWidth - 1 > 0
        };

        if (this.state.overflowL !== nextState.overflowL || this.state.overflowR !== nextState.overflowR) {
          this.setState(nextState);
        }
      }

      this.overflowUpdateTimeoutId = undefined;
    });
  };

  onScroll = (ev: Event): void => {
    if (ev.type === "scroll") {
      this.updateOverflow();
    }
  };

  doScrollL = () => {
    const { rootRef } = this;
    if (rootRef) {
      this.setState({
        scrollAnimation: {
          startedAt: Date.now(),
          from: rootRef.scrollLeft,
          to: rootRef.scrollLeft - (rootRef.getBoundingClientRect().width - 140)
        }
      });
    }
  };
  doScrollR = () => {
    const { rootRef } = this;
    if (rootRef) {
      this.setState({
        scrollAnimation: {
          startedAt: Date.now(),
          from: rootRef.scrollLeft,
          to: rootRef.scrollLeft + (rootRef.getBoundingClientRect().width - 140)
        }
      });
    }
  };

  updateScrollPosition = () => {
    this.scrollAnimationId = undefined;

    const { scrollAnimation } = this.state;
    if (scrollAnimation === undefined) {
      return;
    }

    const { startedAt, from, to } = scrollAnimation;
    const now = Date.now();

    const easingPattern = x => (x < 0.5 ? 4 * x * x * x : (x - 1) * (2 * x - 2) * (2 * x - 2) + 1); // eslint-disable-line

    const percentage = Math.min(1, (now - startedAt) / 500);
    const position = from + (to - from) * easingPattern(percentage);

    const { rootRef } = this;
    if (rootRef) {
      const integralPosition = Math.floor(position);
      if (Math.abs(integralPosition - to) < 2) {
        rootRef.scrollLeft = to;
        this.setState({ scrollAnimation: undefined });
      } else {
        rootRef.scrollLeft = integralPosition;
        this.scrollAnimationId = requestAnimationFrame(this.updateScrollPosition);
      }

      this.updateOverflow();
    }
  };

  render() {
    const { images, activeImage, onChange } = this.props;
    const { overflowL, overflowR } = this.state;

    return (
      <div className="MorphSelector">
        <OverflowIndicatorL isVisible={overflowL} doScroll={this.doScrollL} />
        <div ref={this.rootRefFn} className="MorphSelector-Buttons" onScroll={this.onScroll}>
          {images.map((someImage, i) => (
            <button
              key={i}
              className={`MorphSelector-Item${activeImage === someImage ? " MorphSelector-Item--active" : ""}`}
              onClick={() => {
                onChange(someImage);
              }}
            >
              {someImage.morph.label}
            </button>
          ))}
        </div>
        <OverflowIndicatorR isVisible={overflowR} doScroll={this.doScrollR} />
      </div>
    );
  }
}

MorphSelector.propTypes = {
  images: PropTypes.array.isRequired,
  activeImage: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MorphSelector;

const OverflowIndicatorL = ({ isVisible, doScroll }: { isVisible: boolean, doScroll(): void }) => (
  <div>
    <div className="MorphSelector-OverflowIndicatorL-Backdrop" style={{ opacity: isVisible ? 1 : 0 }} />
    {isVisible && (
      <div className="MorphSelector-OverflowIndicatorL-Button" onClick={doScroll}>
        <LeftArrow color={"currentColor"} />
      </div>
    )}
  </div>
);

const OverflowIndicatorR = ({ isVisible, doScroll }: { isVisible: boolean, doScroll(): void }) => (
  <div>
    <div className="MorphSelector-OverflowIndicatorR-Backdrop" style={{ opacity: isVisible ? 1 : 0 }} />
    {isVisible && (
      <div className="MorphSelector-OverflowIndicatorR-Button" onClick={doScroll}>
        <RightArrow color={"currentColor"} />
      </div>
    )}
  </div>
);

const RightArrow = ({ color }: { color: string }) => (
  <svg width="11" height="18" viewBox="0 0 11 18">
    <path fill={color} fillRule="evenodd" d="M1.5 0L0 1.5 7.5 9 0 16.5 1.5 18l9-9" />
  </svg>
);

const LeftArrow = ({ color }: { color: string }) => (
  <svg width="11" height="18" viewBox="0 0 11 18">
    <path fill={color} fillRule="evenodd" d="M9 0L10.5 1.5 3 9 10.5 16.5 9 18l-9-9" />
  </svg>
);
