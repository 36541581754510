import React from "react";
import PropTypes from "prop-types";
import "./TweetText.scss";

import Icon from "../Share/icons/ic_twitter.svg";

const clickToTweet = {
  de: "Zum Tweeten klicken",
  en: "Click to tweet"
}

export class TweetText extends React.PureComponent {
  static propTypes = {
    lang: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
  };

  render() {
    const { lang, children } = this.props;

    const href = (() => {
      const text = encodeURIComponent(children);
      const url = encodeURIComponent(window.location.href);
      return `https://twitter.com/intent/tweet?text=${text}&url=${url}`;
    })();

    const [init, last] = (() => {
      const parts = children.split(" ");
      return [parts.slice(0, parts.length - 1), parts[parts.length - 1]];
    })();

    return (
      <a className="TweetText" href={href} target="_blank" rel="noopener noreferrer">
        {init.join(" ")}{" "}
        <span className="TweetText-nobreak">
          {last}
          <span className="TweetText-icon">
            <span className="TweetText-tooltip">{clickToTweet[lang]}</span>
            <Icon />
          </span>
        </span>
      </a>
    );
  }
}
