import React, { Component, PureComponent } from "react";
import PropTypes from "prop-types";
import { interpolateNumber } from "d3-interpolate";
import { select } from "d3-selection";

import FigureImage from "components/Figure/FigureImage";
import Bubble from "components/Share/Bubble";

import translateImage from "../translateImage";
import passiveEvent from "utils/passiveEvent";

import "./Slideshow.scss";

class Slideshow extends PureComponent {
  render() {
    const { images, aspectRatio, slideshowRef, slideRef, lang } = this.props;
    return (
      <div className={`PageContent`}>
        <div className={`Slideshow`} ref={slideshowRef}>
          <div className="Slideshow-inner">
            <div className="Slideshow-slideSpacer" />
            {images.map((image, i) => (
              <div className="Slideshow-slide" key={image.id} ref={(el) => slideRef(i, el)}>
                <FigureImage
                  zoomable
                  figureClassName="Slideshow-figure"
                  imageClassName={`Slideshow-image Slideshow-image--${aspectRatio}`}
                  {...translateImage(image, lang)}
                />
              </div>
            ))}
            <div className="Slideshow-slideSpacer" />
          </div>
        </div>
      </div>
    );
  }
}

Slideshow.propTypes = {
  images: PropTypes.array.isRequired,
  aspectRatio: PropTypes.oneOf(["portrait", "landscape"]).isRequired,
  lang: PropTypes.string.isRequired,
};

const scrollLeftTween = (el, targetScrollLeft) => () => {
  const interpolateScrollLeft = interpolateNumber(el.scrollLeft, targetScrollLeft);
  return (t) => {
    el.scrollLeft = interpolateScrollLeft(t);
  };
};

// Return a list of tuples slide(id) and the distance from the center of the viewport.
// The distance is signed. Negative is left of the center, positive right of the center.
const slidesWithDistance = (container: HTMLElement, images, slides): { slide: number, d: number }[] => {
  const viewportWidth = container.clientWidth;
  const horizontalCenter = viewportWidth / 2;

  // Horizontal distance from the center of the given element to the center
  // of the viewport. Signed, negative is left of the center, positive is
  // right of the center.
  const distanceFromCenter = (el: HTMLElement): number => {
    const { left, width } = el.getBoundingClientRect();
    return left + width / 2 - horizontalCenter;
  };

  // The 'images' array is only used to get the list of IDs. Using
  // 'Object.keys(slides)' is too expensive.
  return images.map((_, id) => ({
    slide: id,
    d: distanceFromCenter(slides[id]),
  }));
};

// There are two options what we consider the current slide:
//
//  1. One particular slide if the image is exactly at the center of the
//     viewport (or in close viscinity). In that case, the next/prev buttons
//     go to the next or previous slide. This mode is used exclusively
//     as long as the user only uses the buttons to scroll through the slideshow.
//
//  2. If the scroll position is such that that we can not conclusively determine
//     which of the two possible images is the 'current', we take both. In that
//     case the prev/next buttons go to the left or right slide.
//
// This function returns the slide(-ids) that should be focused when the user
// presses the prev/next button. The indices may be outside of the valid range,
// make sure to clamp them before using as the key into the 'slides' map.

const currentSlide = (container: HTMLElement, images, slides): { prev: number, next: number } => {
  // If the distance is below that number, we consider the element to be
  // centered.
  const eps = 100;

  // Collect what we consider the prev, center, next slides.
  const { center, prev, next } = slidesWithDistance(container, images, slides).reduce(
    (a, { slide, d }) => ({
      center: Math.abs(d) < eps ? slide : a.center,
      prev: a.prev === undefined || d < 0 ? slide : a.prev,
      next: a.prev !== undefined && a.next === undefined && d > 0 ? slide : a.next,
    }),
    { center: undefined, prev: undefined, next: undefined }
  );

  // If we have a conclusive center, yay. Otherwise use whatever is prev/next.
  return center !== undefined ? { prev: center - 1, next: center + 1 } : { prev, next };
};

class SlideshowWithState extends Component {
  constructor() {
    super();
    this.state = {
      slide: 0,
      showPreviousButton: false,
      showNextButton: true,
    };

    this.slides = {};

    // Low-level state update function. Automatically manages the showPreviousButton/showNextButton fields.
    this.goToSlide = (slide, then) => {
      const showPreviousButton = this.slideshow.scrollLeft > 0;
      const showNextButton = this.slideshow.scrollLeft < this.slideshow.scrollWidth - this.slideshow.clientWidth;
      if (
        slide !== this.state.slide ||
        showPreviousButton !== this.state.showPreviousButton ||
        showNextButton !== this.state.showNextButton ||
        then
      ) {
        this.setState({ slide, showPreviousButton, showNextButton }, then);
      }
    };

    // Internal implementation of the prev/next button onClick handlers.
    this.slideTo = (f) => {
      const cs = currentSlide(this.slideshow, this.props.images, this.slides);
      const nextSlide = Math.min(this.props.images.length - 1, Math.max(0, f(cs)));
      this.goToSlide(nextSlide, () => this.initiateTransition());
    };

    this.next = () => this.slideTo((x) => x.next);
    this.previous = () => this.slideTo((x) => x.prev);

    // The onScroll function is called from an requestAnimationFrame callback.
    this.onScrollId = undefined;
    this.onScroll = () => {
      this.onScrollId = undefined;
      const slides = slidesWithDistance(this.slideshow, this.props.images, this.slides).sort(
        (a, b) => Math.abs(a.d) - Math.abs(b.d)
      );
      if (slides.length > 0) {
        this.goToSlide(slides[0].slide);
      }
    };
    this.onScrollListener = () => {
      if (!this.onScrollId) {
        this.onScrollId = requestAnimationFrame(this.onScroll);
      }
    };

    this.containerRef = (el) => {
      this.container = el;
    };
    this.slideshowRef = (el) => {
      this.slideshow = el;
      if (el) {
        el.addEventListener("scroll", this.onScrollListener, passiveEvent());
      }
    };
    this.slideRef = (id, el) => {
      this.slides[id] = el;
    };

    // The onResize function is also called from rAF.
    this.onResizeId = undefined;
    this.onResize = () => {
      this.onResizeId = undefined;

      if (this.container && this.slideshow) {
        const viewportWidth = this.slideshow.clientWidth;
        const slideshowWidth = this.slideshow.scrollWidth;

        select(this.container)
          .selectAll(".SlideshowContainer-button")
          .style("display", slideshowWidth <= viewportWidth ? "none" : null);
      }
    };
    this.onResizeListener = () => {
      this.onScrollListener();
      if (!this.onResizeId) {
        this.onResizeId = requestAnimationFrame(this.onResize);
      }
    };
  }

  componentDidMount() {
    // Ugly hack to wait for browser layout
    setTimeout(this.onResizeListener, 100);
    window.addEventListener("resize", this.onResizeListener, passiveEvent());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeListener);
    if (this.onScrollId) {
      cancelAnimationFrame(this.onScrollId);
      this.onScrollId = undefined;
    }
    if (this.onResizeId) {
      cancelAnimationFrame(this.onResizeId);
      this.onResizeId = undefined;
    }
  }

  // The transition is only applied when the user scrolls with the prev/next buttons.
  // This code must not be executed when the user scrolls using fingers. It is therefore
  // only called from the 'setState' callback, not from 'componentDidUpdate'.
  initiateTransition() {
    const nextSlide = this.slides[this.state.slide];
    if (!this.container || !this.slideshow || !nextSlide) {
      return;
    }

    const viewportWidth = this.slideshow.clientWidth;
    const slideshowWidth = this.slideshow.scrollWidth;

    const { left, width } = nextSlide.getBoundingClientRect();
    const targetScrollLeft = Math.min(
      slideshowWidth - viewportWidth,
      Math.max(0, left + width / 2 + this.slideshow.scrollLeft - viewportWidth / 2)
    );

    select(this.slideshow)
      .transition("slide")
      .duration(500)
      .tween("scroll", scrollLeftTween(this.slideshow, targetScrollLeft));

    select(this.container)
      .selectAll(".SlideshowContainer-button")
      .style("display", slideshowWidth <= viewportWidth ? "none" : null);
  }

  render() {
    return (
      <div className="SlideshowContainer" ref={this.containerRef}>
        <Slideshow {...this.props} slideshowRef={this.slideshowRef} slideRef={this.slideRef} />
        <div
          className="SlideshowContainer-fade SlideshowContainer-fade--previous"
          style={{ opacity: this.state.showPreviousButton ? 1 : 0 }}
        ></div>
        <div
          className="SlideshowContainer-fade SlideshowContainer-fade--next"
          style={{ opacity: this.state.showNextButton ? 1 : 0 }}
        ></div>
        {this.state.showPreviousButton && (
          <button
            aria-hidden
            className="SlideshowContainer-button SlideshowContainer-button--previous"
            onClick={this.previous}
          >
            <Bubble large icon="arrow_left" />
          </button>
        )}
        {this.state.showNextButton && (
          <button aria-hidden className="SlideshowContainer-button SlideshowContainer-button--next" onClick={this.next}>
            <Bubble large icon="arrow_right" />
          </button>
        )}
      </div>
    );
  }
}

SlideshowWithState.propTypes = {
  ...Slideshow.propTypes,
};

export default SlideshowWithState;
