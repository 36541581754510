import React from 'react';
import PropTypes from 'prop-types';

import FigureImage from 'components/Figure/FigureImage';
import translateImage from '../translateImage';

import './Image.scss';

const StoryImage = ({images, layout, lang}) => (
  layout === 'full-width'
  ? (
    <div className={`PageContent`}>
      <div className={`StoryImages`}>
        {images.map(image => <FigureImage key={image.id} {...translateImage(image, lang)} captionClassName='StoryImages-caption' mappable zoomable />)}
      </div>
    </div>
  )
  : (
    <div className={`PageContent PageContent--narrow`}>
      <div className={`StoryImages ${layout === 'float' ? 'StoryImages--float' : ''}`}>
        {images.map(image => <FigureImage key={image.id} {...translateImage(image, lang)} />)}
      </div>
    </div>
  )
);

StoryImage.propTypes = {
  images: PropTypes.array.isRequired,
  layout: PropTypes.oneOf(['inline', 'full-width', 'float']).isRequired,
  lang: PropTypes.string.isRequired
};

export default StoryImage;
