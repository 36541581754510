/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";

import "./Quote.scss";

const Quote = ({ text, source }) => (
  <div className="PageContent PageContent--narrow">
    <blockquote className="Quote">
      {text
        .split("\n\n")
        .filter((paragraph) => paragraph !== "")
        .map((paragraph, i) => (
          <p key={i} className={`Quote-paragraph`} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      {source && <cite className="Quote-source">—&nbsp;{source}</cite>}
    </blockquote>
  </div>
);

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  source: PropTypes.string,
};

export default Quote;
