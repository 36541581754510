/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";

export const Acknowledgments = ({ lang, author, editors, thanks, doi }) => (
  <aside className="PageContent PageContent--narrow StyledContent">
    <dl>
      <dt>Text</dt>
      <dd dangerouslySetInnerHTML={{ __html: author }} />

      <dt>{lang === "de" ? "Redaktion" : "Editorial staff"}</dt>
      <dd dangerouslySetInnerHTML={{ __html: editors }} />

      <dt>{lang === "de" ? "Wir danken" : "Thanks to"}</dt>
      <dd dangerouslySetInnerHTML={{ __html: thanks }} />

      <dt>{lang === "de" ? "DOI Link" : "DOI link"}</dt>
      <dd>
        <a href={`https://doi.org/${doi}`} rel="external">
          {doi}
        </a>
      </dd>
    </dl>
  </aside>
);

Acknowledgments.propTypes = {
  lang: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  editors: PropTypes.string.isRequired,
  thanks: PropTypes.string.isRequired,
  doi: PropTypes.string.isRequired
};
