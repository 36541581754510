import { objectFit } from "utils/objectFitPolyfill";

// Add a timer to switch to the SD version of the video if it takes
// too long to load. The video element should already be initialised
// (eg. src !== '').
//
// The logic here is the same as in Splash.js.
const gracefullyDegrade = (el: HTMLVideoElement): void => {
  setTimeout(() => {
    if (el.readyState < 4) {
      el.src = el.getAttribute("data-src-sd");
    }
  }, 10 * 1000);
};

(() => {
  const videoElements = Array.from(document.querySelectorAll(".js-home-bg-video"));

  videoElements.forEach(el => {
    // Apply objectFit once now, and also whenever the metadata is loaded (for
    // videos which are loaded dynamically). Only when the metadata is available
    // does the browser know the true video dimensions. Without those the polyfill
    // does not work correctly.
    objectFit(el);
    el.addEventListener("loadedmetadata", () => {
      objectFit(el);
    });

    // Set up graceful degradation if the element 'src' was already initialised
    // in PHP. This is currently only the case for the primary article.
    if (el.src !== "") {
      gracefullyDegrade(el);
    }
  });

  // The objectFit polyfill needs to recalculate the position when the container changes.
  // We're not building a single-page application, so it's safe to attach event listeners
  // to window, they will be disposed if the user navigates to a different page.
  window.addEventListener("resize", () => {
    videoElements.forEach(objectFit);
  });

  const play = videoElement => () => {
    videoElements.forEach(el => {
      if (el === videoElement) {
        // Need to set the video src if it wasn't set previously (either in PHP
        // or by a previous call to this function).
        if (el.src === "") {
          el.src = el.getAttribute("data-src-hd");
          gracefullyDegrade(el);
        }

        // Catch (and ignore) errors thrown from the 'play()' request. This happens
        // when the user moves the mouse quickly in and out of an article element,
        // and the play request doesn't have a chance to begin playback before
        // we pause the video again. No harm done, just ignore the error.
        //
        // This also has the effect of ignoring any other errors which are caused
        // by the browser not being able to play the video (unsupported format,
        // file doesn't exist, network problems etc).
        //
        // And... '.play()' was changed to return a Promise in a later version of the
        // spec. In Edge it returns undefined;
        const p = el.play();
        if (p) p.catch(() => 0);
      } else {
        el.pause();
      }
    });
  };

  videoElements.forEach(el => {
    // The mouseenter/mouseleave event handlers can not be attached to the
    // video element, because it is hidden behind other elements. Safest
    // is to attach them to the containing <article>.
    const article = (() => {
      let it = el;
      while (it && it.nodeName !== "ARTICLE") {
        it = it.parentNode;
      }
      return it;
    })();

    if (article) {
      article.addEventListener("mouseenter", play(el), false);
    }
  });
})();
