import React from "react";
import PropTypes from "prop-types";
import "./License.scss";
import ccLogo from "./img_cc-by-sa.svg";

const content = {
  de: () => (
    <p>
      Dieses Werk unterliegt einer{" "}
      <a rel="external" href="https://creativecommons.org/licenses/by-sa/4.0/deed.de">
        Creative Commons Attribution-ShareAlike 4.0 International Public License
      </a>
    </p>
  ),
  en: () => (
    <p>
      This work is licensed under the{" "}
      <a rel="external" href="https://creativecommons.org/licenses/by-sa/4.0/deed.en">
        Creative Commons Attribution-ShareAlike 4.0 International Public License
      </a>
    </p>
  )
};

export const License = ({ lang }) => (
  <aside className="License">
    <div className="PageContent PageContent--narrow">
      {React.createElement(content[lang] || content.en)}
      <img className="License-logo" src={ccLogo} alt="" />
    </div>
  </aside>
);

License.propTypes = {
  lang: PropTypes.string.isRequired
};
