import { array } from "fp-ts";

export const extractChapters = (blocks, { isWordpressPreview }) => {
  const chapters = [];

  const { init, rest } = isWordpressPreview
    ? { init: blocks, rest: [] }
    : array.spanLeft((block) => block.blockType !== "short-story-sequel-teaser")(blocks);

  init.forEach((block, i) => {
    if (block.blockType === "short-story-chapter") {
      chapters.push({ id: `block-${i}`, title: block.title, label: block.toc });
    }
  });

  rest.forEach((block) => {
    if (block.blockType === "short-story-chapter") {
      chapters.push({ title: block.title, label: block.toc });
    }
  });

  return { blocks: [...init, ...rest.slice(0, 1)], chapters };
};
