import React from "react";
import PropTypes from "prop-types";

const MissingComponent = ({ blockType, ...props }) => (
  <div
    style={{
      background: "red",
      padding: 20,
      boxSizing: "border-box",
      maxWidth: 600,
      margin: "20px auto",
      overflow: "auto"
    }}
  >
    <h2 style={{ color: "white" }}>{`Missing Component type "${blockType}"`}</h2>
    <pre>
      <code>{JSON.stringify(props, null, 2)}</code>
    </pre>
  </div>
);

MissingComponent.propTypes = {
  blockType: PropTypes.string.isRequired
};

export default MissingComponent;
