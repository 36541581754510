/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import './FullscreenCaption.scss';

const FullscreenCaption = ({title, caption, sourceUrl, sourceText, images}) => (
  <div className='FullscreenCaption'>
    <div className='FullscreenCaption-content'>
      <span className='FullscreenCaption-title'>{title}</span>
      <span dangerouslySetInnerHTML={{__html: caption}} />
      {
        sourceUrl && sourceText
        ? <a className='FullscreenCaption-link' href={sourceUrl}>{sourceText}</a>
        : null
      }
    </div>
    {
      images &&
      <div className='FullscreenCaption-images'>
        {images.map((image, i) => (
          <div
            key={i}
            className='FullscreenCaption-image'
            role='presentation'
            style={{backgroundImage: `url(${image.src})`}}
          />
        ))}
      </div>
    }
  </div>
);

FullscreenCaption.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  sourceUrl: PropTypes.string,
  sourceText: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({src: PropTypes.string.isRequired}))
};

export default FullscreenCaption;
