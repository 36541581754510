import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {select} from 'd3-selection';
import 'd3-transition';

import './MappedImage.scss';

/* Keep this in sync with the duration that is defined in the SCSS file! */
const ZOOM_DURATION = 1000;

export class MappedImage extends Component {
  static propTypes = {
    fadeIn: PropTypes.bool,
    src: PropTypes.string.isRequired,
    onFadeIn: PropTypes.func.isRequired,
    onFadeOut: PropTypes.func.isRequired,
  }

  constructor() {
    super();
    this.state = {
      fadingIn: false,
      opacity: 0
    };

    this.el = null;
    this.elRef = (el) => {this.el = select(el);};
  }

  componentDidMount() {
    if (this.props.fadeIn) {
      this.fadeIn();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({fadeIn}) {
    if (this.props.fadeIn === fadeIn) {
      return;
    }
    if (fadeIn === false) {
      this.fadeOut();
    } else {
      this.fadeIn();
    }
  }

  fadeIn = () => {
    this.el
    .transition()
      .duration(ZOOM_DURATION)
      .tween('fade', () => (t) => {
        this.setState(() => ({opacity: t}));
      })
      .on('end', this.props.onFadeIn);
  }

  fadeOut = () => {
    this.el
    .transition()
      .duration(ZOOM_DURATION)
      .tween('fade', () => (t) => {
        this.setState(() => ({opacity: 1 - t}));
      })
      .on('end', this.props.onFadeOut);
  }

  render() {
    const {src} = this.props;
    const {opacity} = this.state;

    return (
      <div ref={this.elRef} className='MappedImage'>
        <div className={`MappedImage-backdrop`}
          style={{
            opacity
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            opacity: opacity,
            backgroundImage: `url(${src})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%'
          }} />
      </div>
    );
  }
}
