import React from "react";
import PropTypes from "prop-types";

import "./ExplanationRef.scss";

export class ExplanationRef extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired
  };

  render() {
    const { id, text } = this.props;

    return (
      <span className="Explanation-Ref">
        <span className="Explanation-Ref-target" id={`explanation-ref-${id}`} />
        <a className="Explanation-Ref-link" href={`#explanation-${id}`}>
          {text}
        </a>
      </span>
    );
  }
}
