import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Bubble from './Bubble';
import plattforms, {defaultProps, propTypes} from './plattforms';

import './Menu.scss';

let id = 1;
class Menu extends Component {
  constructor(props) {
    super(props);
    this.id = `ShareMenu-${id++}`;
    this.state = {};
    this.focus = () => this.setState({open: true});
    this.blur = () => this.setState({open: false});
    this.toggle = () => this.setState({open: !this.state.open});
  }
  render() {
    const {open} = this.state;
    const {transparent} = this.props;

    return (
      <span className={classNames(open && 'ShareMenu--open')}>
        <span className='ShareMenu-blinder' onTouchEnd={this.blur} />
        <span className='ShareMenu'
          onMouseEnter={this.focus}
          onMouseLeave={this.blur}>
          <button onTouchEnd={this.toggle} aria-controls={this.id} aria-expanded={open}>
            <Bubble icon={open ? 'close' : 'share'} transparent={!open && transparent} />
          </button>
          <ul role='menu' id={this.id} aria-expanded={open} onClick={this.blur} className='ShareMenu-options'>
            {plattforms.map(({href, icon, target}, i) => {
              return <li key={i} role='menuitem'><a href={href(this.props)} target={target}><Bubble icon={icon} /></a></li>;
            })}
          </ul>
        </span>
      </span>
    );
  }
}

Menu.defaultProps = defaultProps;

Menu.propTypes = {
  ...propTypes,
  transparent: PropTypes.bool
};

export default Menu;
