import React from "react";
import PropTypes from "prop-types";

import "./BlockAnchor.scss";

const BlockAnchor = ({ blockId }) => <div id={`block-${blockId}`} className="BlockAnchor" />;

BlockAnchor.propTypes = {
  blockId: PropTypes.number.isRequired
};

export default BlockAnchor;
