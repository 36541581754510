const FOOTNOTE_PATTERN = /\[note\]([\s\S]*?)\[\/note\]/g;

// footnotes gets mutated 😱
const extractFootnotesFromText = (footnotes, text) => {
  let match;
  let replacedText = text;
  // eslint-disable-next-line no-cond-assign
  while ((match = FOOTNOTE_PATTERN.exec(text))) {
    const id = footnotes.length + 1;
    footnotes.push({ id, text: match[1] });
    replacedText = replacedText.replace(
      match[0],
      `<span class="Footnote-ref">
  <span class="Footnote-ref-target" id="footnote-ref-${id}"></span>
  <a class="Footnote-ref-link" href="#footnote-${id}">${id}</a>
</span>`
    );
  }
  return replacedText;
};

export const extractFootnotes = (blocks, lang) => {
  const result = {
    blocks: [],
    footnotes: []
  };

  blocks.forEach(block => {
    const { blockType } = block;
    if (blockType === "text" || blockType === "quote" || blockType === "infobox") {
      const { text } = block;
      const replacedText = extractFootnotesFromText(result.footnotes, text);
      // Careful to not modify the existing block.text, otherwise it gets saved in the DB and the replacement won't work anymore
      result.blocks.push({
        ...block,
        text: replacedText
      });
    } else if (blockType === "image" || blockType === "slideshow") {
      const replacedImages = block.images.map(image => {
        const { caption } = image;
        const replacedCaption = extractFootnotesFromText(result.footnotes, caption[lang]);
        return {
          ...image,
          caption: {
            ...caption,
            [lang]: replacedCaption
          }
        };
      });
      result.blocks.push({
        ...block,
        images: replacedImages
      });
    } else {
      result.blocks.push(block);
    }
  });

  return result;
};
