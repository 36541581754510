import React from "react";
import PropTypes from "prop-types";

import "./ShortStorySequelTeaser.scss";

interface Props {
  lead?: React.ReactNode;
  title?: React.ReactNode;
  excerpt?: React.ReactNode;
}

export const ShortStorySequelTeaser = ({ lead, title, excerpt }: Props) => {
  return (
    <div className="PageContent PageContent--narrow StyledContent">
      <div className="ShortStorySequelTeaser">
        {lead && <div className="ShortStorySequelTeaser-lead">{lead}</div>}
        {title && <h2 className="ShortStorySequelTeaser-title">{title}</h2>}
        {excerpt && <div className="ShortStorySequelTeaser-excerpt">{excerpt}</div>}
      </div>
    </div>
  );
};

ShortStorySequelTeaser.propTypes = {
  lead: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
};
