import React from "react";
import PropTypes from "prop-types";

import "./TableOfContents.scss";

export const TableOfContents = ({ chapters }) => {
  return (
    <div className="PageContent PageContent--narrow">
      <div className="TableOfContents">
        {chapters.map((chapter, i) => (
          <Chapter key={i} index={i + 1} {...chapter} />
        ))}
      </div>
    </div>
  );
};

TableOfContents.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ).isRequired
};

const Chapter = ({ index, id, title, label }) => {
  const children = (
    <>
      <div className="TableOfContents-Chapter-index">{index}</div>
      <div className="TableOfContents-Chapter-title">{title}</div>
      {label && <div className="TableOfContents-Chapter-label">{label}</div>}
    </>
  );

  if (id) {
    return (
      <a className="TableOfContents-Chapter" href={`#${id}`}>
        {children}
      </a>
    );
  } else {
    return <div className="TableOfContents-Chapter TableOfContents-Chapter--preview">{children}</div>;
  }
};
