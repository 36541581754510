import React from "react";
import PropTypes from "prop-types";

import Text from "components/snowball/Text/Text";
import ShareMenu from "components/Share/Menu";
import BlockAnchor from "components/BlockAnchor/BlockAnchor";

import translateImage from "../translateImage";

import "./Tips.scss";

const Tip = ({ sizes, alt, title, caption, number, layout }) =>
  layout === "tip" ? (
    <div className="Tip">
      <img className="Tip-image" src={sizes.m.src} alt={alt} role="presentation" />
      <h2 className="Tip-title">
        {number}. {title}
      </h2>
      <div className="Tip-text">{caption}</div>
    </div>
  ) : (
    <div className="ImgGrid">
      <div className="ImgGrid-content">
        <h2 className="Tip-title">{title}</h2>
        <div className="Tip-text">{caption}</div>
      </div>
      <img className="ImgGrid-image" src={sizes.m.src} alt={alt} role="presentation" />
    </div>
  );

Tip.propTypes = {
  sizes: PropTypes.shape({ m: PropTypes.object.isRequired }),
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  layout: PropTypes.oneOf(["tip", "image-grid"]).isRequired
};

const currentURLWithQueryParam = (k, v) => {
  const { protocol, host, pathname } = window.location;
  return `${protocol}//${host}${pathname}?${k}=${encodeURIComponent(v)}`;
};

const shareMenuProps = (title, text) => {
  return {
    ...ShareMenu.defaultProps,
    tweet: title,
    subject: title,
    description: text,
    url: currentURLWithQueryParam("share", "tips")
  };
};

const Tips = ({ blockId, title, text, images, lang, layout }) =>
  layout === "tip" ? (
    <div>
      <BlockAnchor blockId={blockId} />
      {text && title && <Text text={text} title={title} shareMenuProps={shareMenuProps(title, text)} />}
      <div className={`PageContent`}>
        <section className="Tips">
          {images.map((image, i) => (
            <Tip key={image.id} {...translateImage(image, lang)} number={i + 1} layout={layout} />
          ))}
        </section>
      </div>
    </div>
  ) : (
    <div>
      <BlockAnchor blockId={blockId} />
      {text && title && <Text text={text} title={title} shareMenuProps={shareMenuProps(title, text)} />}
      <div className={`PageContent PageContent--narrow`}>
        <section className="ImageGrid">
          {images.map(image => (
            <Tip key={image.id} {...translateImage(image, lang)} layout={layout} />
          ))}
        </section>
      </div>
    </div>
  );

Tips.propTypes = {
  blockId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(["tip", "image-grid"]).isRequired
};

export default Tips;
