/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";
import "./Footnotes.scss";

const Footnote = ({ id, text }) => (
  <li className="Footnote">
    <span className="Footnote-target" id={`footnote-${id}`} />
    <span dangerouslySetInnerHTML={{ __html: text }} /> <a href={`#footnote-ref-${id}`}>↩︎</a>
  </li>
);

Footnote.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
};

const title = {
  de: "Fussnoten",
  en: "Footnotes"
};
export const Footnotes = ({ lang, footnotes }) => (
  <div className="PageContent PageContent--narrow StyledContent">
    <div className="Footnotes">
      <h2 className="Text-title">{title[lang]}</h2>
      <ol>
        {footnotes.map(({ id, text }) => (
          <Footnote key={id} id={id} text={text} />
        ))}
      </ol>
    </div>
  </div>
);

Footnotes.propTypes = {
  lang: PropTypes.string.isRequired,
  footnotes: PropTypes.arrayOf(PropTypes.shape(Footnote.propTypes)).isRequired
};
