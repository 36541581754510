/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";
import "./Caption.scss";

const Caption = ({ title, caption, sourceUrl, sourceText }) => (
  <div className="Caption">
    <div className="Caption-title" dangerouslySetInnerHTML={{ __html: title }} />
    <div className="Caption-content" dangerouslySetInnerHTML={{ __html: caption }} />
    {sourceUrl && sourceText ? (
      <a className="Caption-link" href={sourceUrl}>
        {sourceText}
      </a>
    ) : null}
  </div>
);

Caption.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  sourceUrl: PropTypes.string,
  sourceText: PropTypes.string
};

export default Caption;
