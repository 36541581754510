/* global jQuery */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

import "./ArticleRating.scss";

const getCookieKey = (id) => `explora_rating_${id}`;

const STRINGS = {
  de: {
    thankyou: "Danke für die Bewertung.",
    title: "Bewerte diesen Artikel",
  },
  en: {
    thankyou: "Thank you for rating.",
    title: "Rate this Article",
  },
};

const RatingStar = ({ count, onSubmit, onHover, hovered }) => (
  <svg
    className={hovered ? "ArticleRating-star ArticleRating-star--active" : "ArticleRating-star"}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 24 24"
    role="button"
    onMouseOver={() => onHover(count)}
    onClick={() => onSubmit(count)}
  >
    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
  </svg>
);

export const ArticleRating = ({ id, lang }) => {
  const cookie = Cookies.get(getCookieKey(id));

  const [hasRated, setHasRated] = useState(cookie !== undefined);
  const [starsHovered, setStarsHovered] = useState(0);

  const onSubmit = useCallback(
    (rating) => {
      Cookies.set(getCookieKey(id), rating.toString(), { sameSite: "Lax" });
      setHasRated(true);

      const nonce = window.rmp_frontend ? window.rmp_frontend.nonce : null;
      const admin_ajax = window.rmp_frontend ? window.rmp_frontend.admin_ajax : "/wp-admin/admin-ajax.php";

      jQuery.ajax({
        type: "POST",
        url: admin_ajax,
        data: {
          action: "process_rating",
          star_rating: rating,
          postID: id,
          duration: 1,
          nonce: nonce,
        },
        dataType: "JSON",
        success: function () {},
        error: () => {
          Cookies.remove(getCookieKey(id));
          setHasRated(false);
        },
      });
    },
    [id]
  );

  // Don't render anything if cookies are disabled
  if (!navigator.cookieEnabled) {
    return null;
  }

  return (
    <div className="ArticleRating">
      <h2>{STRINGS[lang].title}</h2>
      {hasRated ? (
        <div className="ArticleRating-options">{STRINGS[lang].thankyou}</div>
      ) : (
        <ul role="menu" className="ArticleRating-options" onMouseOut={() => setStarsHovered(0)}>
          <li role="menuitem">
            <RatingStar count={1} onSubmit={onSubmit} onHover={setStarsHovered} hovered={starsHovered >= 1} />
          </li>
          <li role="menuitem">
            <RatingStar count={2} onSubmit={onSubmit} onHover={setStarsHovered} hovered={starsHovered >= 2} />
          </li>
          <li role="menuitem">
            <RatingStar count={3} onSubmit={onSubmit} onHover={setStarsHovered} hovered={starsHovered >= 3} />
          </li>
          <li role="menuitem">
            <RatingStar count={4} onSubmit={onSubmit} onHover={setStarsHovered} hovered={starsHovered >= 4} />
          </li>
          <li role="menuitem">
            <RatingStar count={5} onSubmit={onSubmit} onHover={setStarsHovered} hovered={starsHovered >= 5} />
          </li>
        </ul>
      )}
    </div>
  );
};

ArticleRating.propTypes = {
  id: PropTypes.number.isRequired,
  lang: PropTypes.string.isRequired,
};
