const LOCALE_PATTERN = /^([a-z]{2})_([A-Z]{2})$/;

export const getLanguage = (locale) => {
  const match = LOCALE_PATTERN.exec(locale);
  return match ? match[1] : null;
};

export const getRegion = (locale) => {
  const match = LOCALE_PATTERN.exec(locale);
  return match ? match[2] : null;
};
