import {PropTypes} from 'prop-types';

export default [
  {
    icon: 'facebook',
    href: ({url}) => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    target: '_blank'
  },
  // {
  //   icon: 'pinterest',
  //   href: ({url, description, tweet, image}) => `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}${image ? `&media=${encodeURIComponent(image)}` : ''}&description=${encodeURIComponent(description || tweet)}`,
  //   target: '_blank'
  // },
  {
    icon: 'twitter',
    href: ({tweet, image, url}) => `https://twitter.com/intent/tweet?text=${encodeURIComponent([tweet, image].filter(Boolean).join(' '))}&url=${encodeURIComponent(url)}`,
    target: '_blank'
  },
  {
    icon: 'mail',
    href: ({subject, description, url, tweet}) => `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(`${description || tweet}\n\n${url}`)}`
  }
];

export const defaultProps = {
  url: window.location.href,
  tweet: '',
  subject: ''
};

export const propTypes = {
  url: PropTypes.string.isRequired,
  image: PropTypes.string,
  subject: PropTypes.string.isRequired,
  tweet: PropTypes.string.isRequired,
  description: PropTypes.string
};
