import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Bubble.scss';

const Bubble = ({icon, transparent, opaque, large}) => {
  const Icon = require(`./icons/ic_${icon}.svg`);

  return <Icon className={classNames('ShareBubble', transparent && 'ShareBubble--transparent', opaque && 'ShareBubble--opaque', large && 'ShareBubble--large')} />;
};

Bubble.propTypes = {
  transparent: PropTypes.bool,
  opaque: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  large: PropTypes.bool
};

export default Bubble;
