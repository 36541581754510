import React from "react";
import PropTypes from "prop-types";

import { FigureImageContent } from "components/Figure/FigureImage";
import Caption from "components/Figure/Caption";

import MorphSelector from "./MorphSelector";

import "./Morph.scss";

const figCaptionId = id => `figcaption-${id}`;

const sortImages = images =>
  images.slice().sort((a, b) => parseInt(a.morph.position, 10) - parseInt(b.morph.position, 10));

class Morph extends React.PureComponent {
  state = {
    activeImage: sortImages(this.props.images)[0]
  };

  onChange = activeImage => {
    this.setState({ activeImage });
  };

  render() {
    const { layout } = this.props;
    const { activeImage } = this.state;

    const { id, caption, title, sourceUrl, sourceText } = activeImage;

    const images = sortImages(this.props.images);

    return (
      <div className={layout === "full-width" ? "PageContent" : "PageContent PageContent--narrow"}>
        <figure className={"Image-figure"} aria-labelledby={figCaptionId(id)}>
          <div className="Morph">
            {images.map((someImage, i) => (
              <div key={i} className={`Morph-Image${someImage === activeImage ? " Morph-Image--active" : ""}`}>
                <div className="Image-imageContainer">
                  <FigureImageContent {...someImage} />
                </div>
              </div>
            ))}
          </div>

          <div className={layout === "full-width" ? "PageContent PageContent--narrow" : ""}>
            <MorphSelector images={images} activeImage={activeImage} onChange={this.onChange} />
            <figcaption id={figCaptionId(id)} className="Image-caption">
              <Caption title={title} caption={caption} sourceUrl={sourceUrl} sourceText={sourceText} />
            </figcaption>
          </div>
        </figure>
      </div>
    );
  }
}

Morph.propTypes = {
  images: PropTypes.array.isRequired,
  layout: PropTypes.oneOf(["inline", "full-width"]).isRequired
};

export default Morph;
