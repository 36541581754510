import React from "react";
import PropTypes from "prop-types";

import {
  Audio,
  Splash,
  Text,
  Quote,
  Image,
  Slideshow,
  Morph,
  MissingComponent,
  Vimeo,
  Tips,
  DataCarriers,
  InfoBox,
} from "components/snowball";
import { Section as ShareSection } from "components/Share";
import { Footnotes } from "../Footnotes/Footnotes";
import { Glossary } from "../Glossary/Glossary";
import { License } from "../License/License";
import { ShortStorySequelTeaser } from "../ShortStorySequelTeaser/ShortStorySequelTeaser";
import { ShortStoryChapter } from "../ShortStoryChapter/ShortStoryChapter";
import { NewsletterSubscriptionForm } from "../NewsletterSubscriptionForm/NewsletterSubscriptionForm";
import { ArticleRating } from "../ArticleRating/ArticleRating";
import { Acknowledgments } from "../Acknowledgments/Acknowledgments";
import { TableOfContents } from "../TableOfContents/TableOfContents";
import { getLanguage } from "utils/locale";

import { extractChapters } from "utils/extractChapters";
import { extractFootnotes } from "utils/extractFootnotes";
import { extractExplanations } from "utils/extractExplanations";
import { processTweetTexts } from "utils/processTweetTexts";

const COMPONENT_TYPES = {
  splash: Splash,
  text: Text,
  quote: Quote,
  image: Image,
  slideshow: Slideshow,
  vimeo: Vimeo,
  tips: Tips,
  "data-carriers": DataCarriers,
  morph: Morph,
  infobox: InfoBox,
  "short-story-sequel-teaser": ShortStorySequelTeaser,
  "short-story-chapter": ShortStoryChapter,
};

const Article = ({
  id,
  title,
  subtitle,
  date,
  categories,
  headerVideoUrlHd,
  headerVideoUrlSd,
  isShortStory,
  audioStory,
  splashImageUrl,
  blocks,
  shareProps,
  locale,
  acknowledgments,
  preview,
  newsletterSubscriptionFormProps,
  tag,
  isWordpressPreview,
}) => {
  const lang = getLanguage(locale);
  const { blocks: processedBlocks, footnotes, terms, chapters } = (() => {
    const res1 = extractFootnotes(blocks, lang);
    const res2 = extractExplanations(res1.blocks);
    const res3 = processTweetTexts(lang, res2.blocks);
    const res4 = preview ? { blocks: res3, chapters: [] } : extractChapters(res3, { isWordpressPreview });

    return {
      blocks: res4.blocks,
      footnotes: res1.footnotes,
      terms: res2.explanations,
      chapters: res4.chapters,
    };
  })();
  return (
    <>
      {!preview && (
        <Splash
          title={title}
          subtitle={subtitle}
          category={categories[0]}
          date={date}
          videoHd={headerVideoUrlHd}
          videoSd={headerVideoUrlSd}
          image={splashImageUrl}
          isShortStory={isShortStory}
          tag={tag}
        />
      )}
      {audioStory && <Audio title={title} url={audioStory} lang={lang} />}
      {!preview && isShortStory ? <TableOfContents chapters={chapters} /> : null}
      {processedBlocks.map((block, i) => {
        const Component = COMPONENT_TYPES[block.blockType] || MissingComponent;
        return <Component key={i} blockId={i} {...block} lang={lang} />;
      })}
      {terms.length > 0 && <Glossary lang={lang} terms={terms} />}
      {footnotes.length > 0 && <Footnotes lang={lang} footnotes={footnotes} />}
      {!preview && shareProps && (
        <div className="PageContent PageContent--narrow StyledContent">
          <ShareSection {...shareProps} />
        </div>
      )}
      {!preview && (
        <div className="PageContent PageContent--narrow StyledContent">
          <ArticleRating id={id} lang={lang} />{" "}
        </div>
      )}
      {!preview && <NewsletterSubscriptionForm lang={lang} {...newsletterSubscriptionFormProps} />}
      {!preview && <Acknowledgments lang={lang} {...acknowledgments} />}
      {!preview && <License lang={lang} />}
    </>
  );
};

Article.propTypes = {
  id: PropTypes.number.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.string.isRequired,
    })
  ).isRequired,
  shareProps: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.array,
  headerVideoUrlHd: PropTypes.string,
  headerVideoUrlSd: PropTypes.string,
  audioStory: PropTypes.string,
  splashImageUrl: PropTypes.string,
  locale: PropTypes.string.isRequired,
  acknowledgments: PropTypes.object,
  preview: PropTypes.bool,
  newsletterSubscriptionFormProps: PropTypes.object,
  tag: PropTypes.node,
  isWordpressPreview: PropTypes.bool,
};

export default Article;
