import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";

import "index.scss";

import "header";
import "home";
import "gallery";

import Article from "components/Article/Article";
import * as Share from "components/Share";

// Extract page-wide share props (see the 'eth_explora_share_props' function).
const shareProps = (() => {
  try {
    const shareElement = document.querySelector(".Header-share");
    return JSON.parse(shareElement.getAttribute("data-share-props"));
  } catch (_) {
    return {};
  }
})();

// Header Share Menu
(() => {
  const shareElement = document.querySelector(".Header-share");
  if (shareElement) {
    ReactDOM.render(<Share.Menu {...shareProps} />, shareElement);
  }
})();

// Article
(() => {
  const container = document.querySelector("article");
  if (container && window.snowball && window.snowballBlocks) {
    // The container already contains the whole article (pre-rendered, pulled from
    // the WordPress database). However, ReactDOM.render doesn't reliably update
    // the existing DOM. For example, I've seen it not update node attributes.
    //
    // This problem could come from the fact that we pre-rendered the article using
    // a different version of React than we use now. In any case, it's safest to
    // clear the DOM before we render the article into it.

    container.innerHTML = "";

    ReactDOM.render(
      <Article
        {...window.snowball}
        blocks={window.snowballBlocks}
        shareProps={shareProps}
        newsletterSubscriptionFormProps={window.newsletterSubscriptionFormProps || {}}
      />,
      container
    );
  }
})();
