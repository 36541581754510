import React, { Component } from "react";
import PropTypes from "prop-types";
import { objectFit } from "utils/objectFitPolyfill";

import "./Splash.scss";

class Video extends Component {
  static propTypes = {
    hd: PropTypes.string.isRequired,
    sd: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    let shouldPlay = false;
    if (document && document.documentElement.clientWidth > 720) {
      shouldPlay = true;
    }
    this.state = {
      resolution: "hd",
      shouldPlay,
    };
    this.elRef = (el) => {
      this.el = el;
      if (el) {
        objectFit(el);
      }
    };
  }

  componentDidMount() {
    this.startHdTimeout();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.startHdTimeout();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  startHdTimeout = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      // 'this.el' may be undefined if shouldPlay is false, and the video
      // element therefore was never created.
      if (this.el && this.el.readyState < 4) {
        this.setState({ resolution: "sd" });
      }
    }, 10000);
  };

  render() {
    const { resolution, shouldPlay } = this.state;
    const src = this.props[resolution];

    if (!shouldPlay) {
      return null;
    }

    return (
      <video
        className="Splash-videoElement"
        data-object-fit="cover"
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        ref={this.elRef}
        loop
        muted
        autoPlay
        playsInline
        preload="auto"
        src={src}
      />
    );
  }
}

const Title = ({ tag, category, title, subtitle, date, isShortStory }) => (
  <header>
    <div className="Home-titleContainer">
      {tag && (
        <div className={`Home-tag${isShortStory ? " Home-tag--short-story" : ""}`}>
          {isShortStory && (
            <img className="Home-tagIcon" src="/wp-content/themes/eth-explora/static/ic_clock.svg" alt="" />
          )}
          {tag}
        </div>
      )}
      <h1 className="Home-title">
        <span>{title}</span>
      </h1>
      <p className="Home-subtitle">
        <span>{subtitle}</span>
      </p>
      <div className="Home-meta">
        {!isShortStory && (
          <>
            <div className="Home-date">{date}</div>&nbsp;&ndash;&nbsp;
          </>
        )}
        <ul className="post-categories">
          <li>{category}</li>
        </ul>
      </div>
    </div>
  </header>
);

Title.propTypes = {
  tag: PropTypes.node,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isShortStory: PropTypes.bool,
};

const Splash = ({ videoHd, videoSd, image, ...titleProps }) => (
  <div className="Splash" style={{ backgroundImage: `url(${image})` }}>
    <div className="Splash-video" style={{ width: "100%", height: "100%", position: "relative", overflow: "hidden" }}>
      <Video hd={videoHd} sd={videoSd} />
    </div>
    <div className="Splash-inner">
      <Title {...titleProps} />
    </div>
  </div>
);

Splash.propTypes = {
  videoHd: PropTypes.string.isRequired,
  videoSd: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isShortStory: PropTypes.bool,
};

export default Splash;
