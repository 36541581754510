import React from 'react';
import PropTypes from 'prop-types';

import './DataCarriers.scss';
import Caption from 'components/Figure/Caption';

import largeDe from './visualization_datacarriers_de.svg';
import largeEn from './visualization_datacarriers_en.svg';
import smallDe from './visualization_datacarriers_mobile_de.svg';
import smallEn from './visualization_datacarriers_mobile_en.svg';

const VISUALIZATIONS = {
  large: {
    de: largeDe,
    en: largeEn,
  },
  small: {
    de: smallDe,
    en: smallEn,
  },
};

const DataCarriers = ({title, caption, lang}) => (
  <figure
    aria-labelledby={'data-carriers-figcaption'}
    className={'Image-figure DataCarriers-figure'}
  >
    <div className='DataCarriers'>
      <div className='PageContent PageContent--wide'>
        <img className='DataCarriers-large' src={VISUALIZATIONS.large[lang]} alt='' />
        <img className='DataCarriers-small' src={VISUALIZATIONS.small[lang]} alt='' />
      </div>
    </div>

    <div className='PageContent PageContent--narrow'>
      <figcaption id={'data-carriers-figcaption'} className={'Image-caption'}>
        <Caption title={title} caption={caption} />
      </figcaption>
    </div>
  </figure>
);

DataCarriers.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

export default DataCarriers;
