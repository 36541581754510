/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";
import "./Glossary.scss";

const Term = ({ id, term, definition }) => (
  <li className="Glossary-Term">
    <span className="Glossary-Term-target" id={`explanation-${id}`} />
    <span className="Glossary-Term-term">{term}:</span> <span dangerouslySetInnerHTML={{ __html: definition }} />{" "}
    <a href={`#explanation-ref-${id}`}>↩︎</a>
  </li>
);

Term.propTypes = {
  id: PropTypes.number.isRequired,
  term: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired
};

const title = {
  de: "Glossar",
  en: "Glossary"
};

export const Glossary = ({ lang, terms }) => (
  <div className="PageContent PageContent--narrow StyledContent">
    <div className="Glossary">
      <h2 className="Text-title">{title[lang]}</h2>
      <ul className="Glossary-Items">
        {terms.map((term, i) => (
          <Term key={i} {...term} />
        ))}
      </ul>
    </div>
  </div>
);

Glossary.propTypes = {
  lang: PropTypes.string.isRequired,
  terms: PropTypes.arrayOf(PropTypes.shape(Term.propTypes)).isRequired
};
