import React from "react";
import ReactDOM from "react-dom/server";
import { ExplanationRef } from "components/Glossary/ExplanationRef";

const EXPLANATION_PATTERN = /\[explain text="([\s\S]*?)" term="([\s\S]*?)"\]([\s\S]*?)\[\/explain\]/g;

const extractExplanationsFromText = (text, startIndex) => {
  let match;
  let replacedText = text;
  const explanations = [];
  // eslint-disable-next-line no-cond-assign
  while ((match = EXPLANATION_PATTERN.exec(text))) {
    const id = startIndex + explanations.length + 1;
    const explanation = { id, text: match[1], term: match[2], definition: match[3] };
    explanations.push(explanation);
    replacedText = replacedText.replace(
      match[0],
      ` ${ReactDOM.renderToStaticMarkup(<ExplanationRef {...explanation} />)} `
    );
  }
  return { text: replacedText, explanations };
};

export const extractExplanations = blocks => {
  const result = {
    blocks: [],
    explanations: []
  };

  blocks.forEach(block => {
    const { blockType } = block;
    if (blockType === "text") {
      const { text, explanations } = extractExplanationsFromText(block.text, result.explanations.length);
      result.blocks.push({ ...block, text, explanations });
      result.explanations.splice(result.explanations.length, 0, ...explanations);
    } else {
      result.blocks.push(block);
    }
  });

  return result;
};
