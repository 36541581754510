import React from "react";
import PropTypes from "prop-types";

import Morph from "components/Morph/Morph";
import translateImage from "../translateImage";

const MorphBlock = props => <Morph {...props} images={props.images.map(img => translateImage(img, props.lang))} />;

MorphBlock.propTypes = {
  lang: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  layout: PropTypes.oneOf(["inline", "full-width"]).isRequired
};

export default MorphBlock;
