import * as React from "react";
import FullscreenCaption from "../Figure/FullscreenCaption";
import "./Gallery.scss";

const resizeGalleryImage = (galleryStyle, item) => {
  const rowHeight = parseInt(galleryStyle.getPropertyValue("grid-auto-rows"), 10);
  const rowGap = parseInt(galleryStyle.getPropertyValue("grid-row-gap"), 10);
  const rowSpan = Math.ceil((item.querySelector('.Image-figure').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
  item.style.gridRowEnd = "span " + rowSpan;
  item.style.opacity = "1";
};

export const resizeGallery = gallery => {
  const galleryStyle = window.getComputedStyle(gallery);
  Array.from(gallery.getElementsByClassName("Gallery-image")).forEach(el => {
    resizeGalleryImage(galleryStyle, el);
  });
};

export const resizeAllGalleries = () => {
  const galleryCollection = document.getElementsByClassName("Gallery");
  for (const gallery of galleryCollection) {
    resizeGallery(gallery);
  }
};

const imageSizes = [
  { width: 1024, height: 768 },
  { width: 1200, height: 600 },
  { width: 800, height: 800 },
  { width: 600, height: 1000 }
];
const imageSize = i => imageSizes[Math.round(i % imageSizes.length)];

export class Gallery extends React.PureComponent {
  rootRef = React.createRef();

  componentDidMount() {
    setTimeout(() => {
      if (this.rootRef.current) {
        resizeGallery(this.rootRef.current);
      }
    }, 100);
  }

  render() {
    return (
      <div className="Gallery" ref={this.rootRef}>
        {[...Array(15).keys()].map(i => {
          const { width, height } = imageSize(i);
          const paddingBottom = `calc(100% * ${height} / ${width})`;

          return (
            <div key={i} className="Gallery-image">
              <figure className="Image-figure">
                <div className="Image-imageContainer" style={{ paddingBottom }}>
                  <img
                    width={width}
                    height={height}
                    src={`https://picsum.photos/${width}/${height}/?image=${100 + i * 20}`}
                    className="Image-image"
                    alt={`${i}`}
                  />
                </div>

                <div className="Gallery-imageOverlay">
                  <div className="Gallery-actions">
                    <a href="#?" download className="Gallery-downloadButton">
                      JPG
                      <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" height="16px">
                        <g fill="white">
                          <path d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64    c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472    c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z" />

                          <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z" />
                        </g>
                      </svg>
                    </a>
                    <a href="#?" download className="Gallery-downloadButton">
                      TIFF
                      <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" height="16px">
                        <g fill="white">
                          <path d="M382.56,233.376C379.968,227.648,374.272,224,368,224h-64V16c0-8.832-7.168-16-16-16h-64c-8.832,0-16,7.168-16,16v208h-64    c-6.272,0-11.968,3.68-14.56,9.376c-2.624,5.728-1.6,12.416,2.528,17.152l112,128c3.04,3.488,7.424,5.472,12.032,5.472    c4.608,0,8.992-2.016,12.032-5.472l112-128C384.192,245.824,385.152,239.104,382.56,233.376z" />

                          <path d="M432,352v96H80v-96H16v128c0,17.696,14.336,32,32,32h416c17.696,0,32-14.304,32-32V352H432z" />
                        </g>
                      </svg>
                    </a>
                  </div>

                  <figcaption className="Gallery-imageCaption">
                    <FullscreenCaption
                      title={`Awesome Image ${i}`}
                      caption="Hierzu gehören auch naturwissenschaftliche Beiträge unterschiedlicher Art."
                    />
                  </figcaption>
                </div>
              </figure>
            </div>
          );
        })}
      </div>
    );
  }
}
