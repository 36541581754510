import React from "react";
import PropTypes from "prop-types";

import "./NewsletterSubscriptionForm.scss";

// The 'nl[]' field is the list number 1,2,... and not the name/label (en, de, ..).
// This code must be kept in sync with the list configuration in wordpress.

export const NewsletterSubscriptionForm = ({ lang, heading, teaserText, emailPlaceholder, buttonLabel }) => (
  <div className="NewsletterSubscriptionForm">
    <aside className="PageContent PageContent--narrow StyledContent">
      <h2>{heading}</h2>
      <p>{teaserText}</p>
      <form action="/?na=s" method="post" target="_blank">
        <input type="hidden" name="na" value="s" />
        <input type="hidden" name="nl[]" value={{ de: 1, en: 2 }[lang]} />
        <input type="email" placeholder={emailPlaceholder} name="ne" defaultValue="" />
        <button type="submit">{buttonLabel}</button>
      </form>
    </aside>
  </div>
);

NewsletterSubscriptionForm.propTypes = {
  lang: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  teaserText: PropTypes.string.isRequired,
  emailPlaceholder: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired
};
