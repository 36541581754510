import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {ZoomedImage} from './ZoomedImage';
import Bubble from 'components/Share/Bubble';
import FullScreenCaption from 'components/Figure/FullscreenCaption';
import ShareMenu from 'components/Share/Menu';

import './ZoomableImage.scss';

const FocalPoint = ({x, y, d, title, caption, images, onClick}) => (
  <div className='ZoomableImage-FocalPointContainer'
    style={{
      width: `${d * 100}%`,
      height: 0,
      paddingBottom: `${d * 100}%`,
      top: `${y * 100}%`,
      left: `${x * 100}%`,
    }}
  >
    <div
      className='ZoomableImage-FocalPoint'
      onClick={() => onClick({x, y, d, title, caption, images})}
    />
  </div>
);

FocalPoint.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  d: PropTypes.number.isRequired,
  title: PropTypes.string,
  caption: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  images: PropTypes.array,
};

const currentURLWithQueryParam = (k: string, v: string): string => {
  const {protocol, host, pathname} = window.location;
  return `${protocol}//${host}${pathname}?${k}=${encodeURIComponent(v)}`;
};

const shareMenuProps = (title: string, text: string, imageId: number) => ({
  ...ShareMenu.defaultProps,
  tweet: title,
  subject: title,
  description: text,
  url: currentURLWithQueryParam('share', `image-${imageId}`),
});

export class ZoomableImage extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    shareTitle: PropTypes.string.isRequired,
    shareText: PropTypes.string.isRequired,
    lowresProps: PropTypes.object.isRequired,
    highresProps: PropTypes.object.isRequired,
    imageClassName: PropTypes.string,
    caption: PropTypes.node,
    focalPoints: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      d: PropTypes.number.isRequired,
      title: PropTypes.string,
      caption: PropTypes.string,
      images: PropTypes.array,
    })).isRequired
  }

  state = {
    zoomed: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentDidUpdate() {
    if (this.state.zooming) {
      this.renderZoomedImage();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({keyCode}) => {
    const {zooming, zoomTo} = this.state;
    // Only trigger if zoomed in and not currently zooming out (i.e. zoomTo !== null)
    if (zooming && zoomTo && keyCode === 27) { // escape
      this.exitZoom();
    }
  }

  enterZoom = (zoomTo) => {
    this.zoomTarget = document.body.appendChild(document.createElement('div'));
    this.setState({
      zooming: true,
      zoomTo: zoomTo,
      zoomCaption: zoomTo.caption ? {caption: zoomTo.caption, title: zoomTo.title, images: zoomTo.images} : null,
      offset: this.el.getBoundingClientRect()
    });
  }

  exitZoom = () => {
    this.setState({
      zoomTo: null,
      zoomedIn: false,
      offset: this.el.getBoundingClientRect()
    });
  }

  exitedZoom = () => {
    document.body.removeChild(this.zoomTarget);
    this.setState({
      zooming: false,
      zoomCaption: null
    });
  }

  enteredZoom = () => {
    this.setState({
      zoomedIn: true,
    });
  }

  renderZoomedImage() {
    const {id, highresProps, lowresProps, caption, shareTitle, shareText} = this.props;
    const {zoomTo, zoomCaption, zoomedIn, offset} = this.state;

    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
          }}
        >
          <ZoomedImage
            zoomTo={zoomTo}
            offset={offset}
            onZoomIn={this.enteredZoom}
            onZoomOut={this.exitedZoom}
            lowresProps={lowresProps}
            highresProps={highresProps}
          />
          <div
            style={{
              position: 'fixed',
              left: 0,
              right: 0,
              bottom: 0,
              opacity: zoomedIn ? 1 : 0,
              transition: 'opacity 0.3s'
            }}
          >
          { zoomCaption
            ? <FullScreenCaption title={zoomCaption.title} caption={zoomCaption.caption} images={zoomCaption.images} />
            : caption
          }
            </div>
          {shareTitle && <div style={{
            background: 'transparent',
            padding: 0,
            position: 'fixed',
            top: 20,
            right: 62,
            opacity: zoomedIn ? 1 : 0,
            transition: 'opacity 0.3s'
          }}>
            <ShareMenu {...shareMenuProps(shareTitle, shareText, id)} />
          </div>}
          <button
            style={{
              background: 'transparent',
              padding: 0,
              position: 'fixed',
              top: 20,
              right: 20,
              opacity: zoomedIn ? 1 : 0,
              transition: 'opacity 0.3s'
            }}
            onClick={this.exitZoom}>
              <Bubble icon={'close'} />
            </button>
        </div>
      ),
      this.zoomTarget
    );
  }

  render() {
    const {lowresProps, focalPoints} = this.props;

    return (
      <div
        style={{
          position: 'relative'
        }}
      >
        <img
          alt={lowresProps.alt}
          {...lowresProps}
          ref={(el) => {this.el = el;}}
        />
        {
          focalPoints
            .filter(({d}) => d > 0)
            .map((point, i) => <FocalPoint key={i} {...point} onClick={this.enterZoom} />)
        }


        {
          // zooming &&
          // focalPoints.map((point, i) => (
          //   <button
          //   key={i}
          //   style={{
          //     position: 'fixed',
          //     top: 20,
          //     right: 50 + i * 30
          //   }}
          //   onClick={() => this.enterZoom(point)}>{i + 1}</button>
          // ))
        }
      </div>
    );
  }
}
