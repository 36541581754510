import React from "react";
import ReactDOM from "react-dom/server";
import { TweetText } from "components/TweetText/TweetText";

const TWEET_PATTERN = /\[tweet]([\s\S]*?)\[\/tweet\]/g;

const processText = (lang, text) => {
  let match;
  let replacedText = text;

  // eslint-disable-next-line no-cond-assign
  while ((match = TWEET_PATTERN.exec(text))) {
    replacedText = replacedText.replace(
      match[0],
      `${ReactDOM.renderToStaticMarkup(<TweetText lang={lang}>{match[1]}</TweetText>)}`
    );
  }

  return replacedText;
};

export const processTweetTexts = (lang, blocks) => {
  return blocks.map(block => {
    const { blockType } = block;
    if (blockType === "text") {
      const text = processText(lang, block.text);
      return { ...block, text };
    } else {
      return block;
    }
  });
};
