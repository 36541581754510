/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";

import "./InfoBox.scss";

const InfoBox = ({ text, title }) => (
  <div className="PageContent PageContent--narrow StyledContent">
    <aside className="InfoBox">
      {title && <h3 className="InfoBox-title">{title}</h3>}

      <div className="InfoBox-content">
        {text.split("\n\n").map((paragraph, i) => (
          <p key={i} className="InfoBox-paragraph" dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      </div>
    </aside>
  </div>
);

InfoBox.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default InfoBox;
